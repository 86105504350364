/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSmartContract } from "../../app/pages/nft/services";
import { copyText } from "../../app/utils/copy";
import useAlert from "../../app/hooks/useAlert";
import { setSmartContract } from "../../app/pages/home/redux/homeAction";

const SmartContract = () => {
  const [data, setData] = useState();
  const { showAlert } = useAlert();
  const dispatch = useDispatch();

  const { selectedCrypto } = useSelector((state) => state.nft);
  const fetchData = async () => {
    const res = await getSmartContract(selectedCrypto);
    if (res?.status) {
      setData(res);
      dispatch(setSmartContract(res));
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedCrypto]);

  return (
    <>
      {data ? (
        <div className="listing-style mb-4 mb-lg-5">
          <ul>
            <li>Contract Address : <span>{data?.contract_address}</span>
              <span
                className="ms-2 ms-lg-4 d-inline-block"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  copyText(data?.contract_address);
                  showAlert("SUCCESS", "Success", "Address Copied");
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 48 48" width="20">
                  <g id="Copy"><path d="m33.46 28.672v-20.937c0-2.481-2.019-4.5-4.5-4.5h-20.937c-2.481 0-4.5 2.019-4.5 4.5v20.937c0 2.481 2.019 4.5 4.5 4.5h20.937c2.481 0 4.5-2.019 4.5-4.5zm-26.937 0v-20.937c0-.827.673-1.5 1.5-1.5h20.937c.827 0 1.5.673 1.5 1.5v20.937c0 .827-.673 1.5-1.5 1.5h-20.937c-.827 0-1.5-.673-1.5-1.5zm33.454-13.844h-3.646c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5h3.646c.827 0 1.5.673 1.5 1.5v20.937c0 .827-.673 1.5-1.5 1.5h-20.936c-.827 0-1.5-.673-1.5-1.5v-4.147c0-.828-.671-1.5-1.5-1.5s-1.5.672-1.5 1.5v4.147c0 2.481 2.019 4.5 4.5 4.5h20.936c2.481 0 4.5-2.019 4.5-4.5v-20.937c0-2.481-2.019-4.5-4.5-4.5z" />
                  </g>
                </svg>
              </span>
            </li>
            <li>Token Id : <span>{data.project_id ? data.project_id : "0"}</span></li>
            <li>Standard Token : <span>{data.token_standard ? data.token_standard : "ERC-1155"}</span></li>
            <li>Blockchain : <span>{data.blockchain ? data.blockchain : "Mumbai"}</span></li>
          </ul>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default React.memo(SmartContract);
