/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-template */
/* eslint-disable new-cap */
/* eslint-disable camelcase */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable multiline-comment-style */
/* eslint-disable no-eq-null */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable no-var */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GoogleApiWrapper } from "google-maps-react";
import { useFormik } from "formik";
import * as Yup from "yup";
import uploadIcon from "../../../../assets/icons/upload.svg";
import useAlert from "../../../hooks/useAlert";
import metamaskService from "../../smartContracts/metamask";
import { setNftId } from "../../nft/redux/nftAction";
import { getCoordinates } from "../../nft/services";
import { uploadImage } from "../services";
import { getTokenLockList } from "../../nft/services";
import { updateGeoLocation, getTotalNft } from "../services";

const UploadImageMapContainer = ({ data, reserve, sold, tokenId, tokens, usernft, userGeoLocation, currentPage = "nature", getMapDetail }) => {
  const { showAlert } = useAlert();
  const dispatch = useDispatch();
  const { isMetamaskInstalled } = useSelector((state) => state.home);
  const { selectedCrypto } = useSelector((state) => state.nft);

  const isAdmin = localStorage.getItem("isAdmin");

  const [showform, setShowform] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [nft, setNft] = useState([]);
  const [userNft, setUserNft] = useState([]);
  const [isShowUpdateBtn, setIsShowUpdateBtn] = useState(false);
  const [shapeCoordinate, setShapeCoordinate] = useState(null);
  const [selectedNft, setSelectedNft] = useState(0);

  const SUPPORTED_FORMATS = [
    "image/jpeg",
    "image/jpg",
    "image/png"
  ]

  const baseUrl = process.env.REACT_APP_SERVER_IMAGE_FOLDER_URL;
  var map = "";
  var drawingManager = "";
  var infoWindow = "";
  var google = window.google;
  var coordinates = [];
  var timeoutHandler = "";
  var highlitBB = "";

  useEffect(() => {
    initMap();
    removeIframe();
  }, []);

  useEffect(() => {
    checkMap();
  }, [selectedCrypto]);

  useEffect(() => {
    if (userNft.length) {
      setShowform(true)
    }
  }, [userNft]);

  const initMap = () => {
    if (document.getElementById("map") == null) {
      checkMap();
      return false;
    }
    const uluru =
      userGeoLocation && userGeoLocation?.latitude && userGeoLocation?.longitude ?
        { lat: parseFloat(userGeoLocation.latitude, 10), lng: parseFloat(userGeoLocation.longitude, 10) }
        : data && data.length
          ? { lat: parseFloat(data[0].latitude, 10), lng: parseFloat(data[0].longitude, 10) }
          : reserve && reserve.length
            ? { lat: parseFloat(reserve[0].latitude, 10), lng: parseFloat(reserve[0].longitude, 10) }
            : sold && sold.length
              ? { lat: parseFloat(sold[0].latitude, 10), lng: parseFloat(sold[0].longitude, 10) }
              : usernft && usernft.length
                ? { lat: parseFloat(usernft[0].latitude, 10), lng: parseFloat(usernft[0].longitude, 10) }
                : "";

    // The map, centered at Uluru
    map = new google.maps.Map(document.getElementById("map"), {
      zoom: 23,
      center: uluru,
      mapTypeId: "terrain",
    });

    if (JSON.parse(isAdmin)) {
      let lastShape;
      drawingManager = new google.maps.drawing.DrawingManager({
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [
            google.maps.drawing.OverlayType.RECTANGLE,
          ],
        },
        rectangleOptions: {
          // strokeOpacity: 5.0,
          strokeWeight: 3.25,
          fillOpacity: 0.55,
          draggable: true,
          editable: true
        }
      });
      drawingManager.setMap(map);

      ["overlaycomplete"].forEach((eventName) => {
        google.maps.event.addListener(drawingManager, eventName, function (e) {
          lastShape = e.overlay;
          lastShape.type = e.type;

          const shapeCoordinates = {
            latitude1: lastShape.getBounds().getSouthWest().lat(),
            longitude1: lastShape.getBounds().getSouthWest().lng(),
            latitude2: lastShape.getBounds().getNorthEast().lat(),
            longitude2: lastShape.getBounds().getNorthEast().lng(),
          }

          setShapeCoordinate(shapeCoordinates);
          getTotalNftCount(shapeCoordinates);
          setIsShowUpdateBtn(true);
          drawingManager.setDrawingMode(null);
          drawingManager.setOptions({ drawingControl: false });

          ["bounds_changed", "dragstart", "drag", "dragend"].forEach((eventName) => {
            google.maps.event.addListener(e.overlay, eventName, function (e) {
              const shapeCoordinates = {
                latitude1: lastShape.getBounds().getSouthWest().lat(),
                longitude1: lastShape.getBounds().getSouthWest().lng(),
                latitude2: lastShape.getBounds().getNorthEast().lat(),
                longitude2: lastShape.getBounds().getNorthEast().lng(),
              }
              setShapeCoordinate(shapeCoordinates);
              getTotalNftCount(shapeCoordinates);
            });
          });
        });
      });

      const clearShapeBtn = document.createElement("button");
      clearShapeBtn.textContent = "Clear Shape";
      clearShapeBtn.classList.add("custom-map-control-button");
      map.controls[google.maps.ControlPosition.TOP_CENTER].push(clearShapeBtn);
      clearShapeBtn.addEventListener("click", () => {
        lastShape.setMap(null);
        setIsShowUpdateBtn(false);
        setSelectedNft(0);
        drawingManager.setOptions({ drawingControl: true });
      })
    }

    infoWindow = new google.maps.InfoWindow();
    let result = [];
    coordinates = [];
    if (sold && sold.length) {
      result = sold.filter((o1) => !usernft.some((o2) => o1.id === o2.id));
    }
    if (data && data.length > 0) {
      for (let index = 0; index < data.length; index++) {
        coordinates.push(calculateCoordinates(data[index], index, "#8DDFB3"));
      }
    }
    if (reserve && reserve.length > 0) {
      for (let index = 0; index < reserve.length; index++) {
        coordinates.push(calculateCoordinates(reserve[index], index, "#FFF282"));
      }
    }
    if (result && result.length > 0) {
      for (let index = 0; index < result.length; index++) {
        coordinates.push(calculateCoordinates(result[index], index, "#DEB69C"));
      }
    }

    if (usernft && usernft.length > 0) {
      for (let index = 0; index < usernft.length; index++) {
        coordinates.push(calculateCoordinates(usernft[index], index, "blue"));
      }
    }
  };

  const checkMap = () => {
    var chkMapInt = setInterval(function () {
      if (document.getElementById("map")) {
        clearInterval(chkMapInt);
        initMap();
      }
    }, 500);
  };

  const calculateCoordinates = (location, index, color) => {
    var top;
    var right;
    var bottom;
    var left
    if (location?.size && location?.size > 1) {
      top = { lat: location?.top_left_latitude, lng: location?.top_left_longitude };
      right = { lat: location?.top_left_latitude, lng: location?.bottom_right_longitude };
      bottom = { lat: location?.bottom_right_latitude, lng: location?.bottom_right_longitude };
      left = { lat: location?.bottom_right_latitude, lng: location?.top_left_longitude };
    } else {
      var miles = 0.000621371;
      // var  1 miles = 10m;
      top = maxLatLongOnBearing(location.latitude, location.longitude, 45, miles);
      right = maxLatLongOnBearing(location.latitude, location.longitude, 135, miles);
      bottom = maxLatLongOnBearing(location.latitude, location.longitude, 225, miles);
      left = maxLatLongOnBearing(location.latitude, location.longitude, 315, miles);
    }

    let isShowModal = location?.image_url && location?.text ? true : false;
    var infoContent = `<div class="infocontent" style="width:200px;"><div class="thumb"> <img src="${baseUrl + location.image_url
      }" alt="ImageHolder" style="width:100%;"></div><div  class="content" style="width:50%;display:inline-block;">${location.text
      }</div></div></div>`;

    return drawSquare(top, right, bottom, left, location, color, infoContent, isShowModal);
  };

  const maxLatLongOnBearing = (lat, lng, bearing, distance) => {
    var lonRads = toRad(lng);
    var latRads = toRad(lat);
    var bearingRads = toRad(bearing);
    var maxLatRads = Math.asin(
      Math.sin(latRads) * Math.cos(distance / 6371) +
      Math.cos(latRads) * Math.sin(distance / 6371) * Math.cos(bearingRads)
    );
    var maxLonRads =
      lonRads +
      Math.atan2(
        Math.sin(bearingRads) * Math.sin(distance / 6371) * Math.cos(latRads),
        Math.cos(distance / 6371) - Math.sin(latRads) * Math.sin(maxLatRads)
      );

    var maxLat = radiansToDegrees(maxLatRads);
    var maxLong = radiansToDegrees(maxLonRads);

    var coordinate = {
      lat: maxLat,
      lng: maxLong,
    };

    return coordinate;
  };

  const toRad = (value) => {
    return (value * Math.PI) / 180;
  };

  const radiansToDegrees = (radians) => {
    var pi = Math.PI;
    return radians * (180 / pi);
  };

  const drawSquare = (top, right, bottom, left, location, color, infoContent, isShowModal) => {
    var flightPlanCoordinates = [
      { lat: top.lat, lng: top.lng },
      { lat: right.lat, lng: right.lng },
      { lat: bottom.lat, lng: bottom.lng },
      { lat: left.lat, lng: left.lng },
    ];

    var square = new google.maps.Polygon({
      path: flightPlanCoordinates,
      geodesic: true,
      strokeColor: "#000000",
      strokeOpacity: 1,
      strokeWeight: 0.35,
      fillColor: color,
      fillOpacity: 0.5,
    });

    square.setMap(map);
    if (color === "#DEB69C" || color === "blue") {
      const cont = document.createElement("div");
      cont.classList.add("infocontent2");
      if (isShowModal) {
        google.maps.event.addListener(square, "mouseover", function (event) {
          popUpModal(this, location, cont, event);
        });
        google.maps.event.addListener(square, "mouseout", function () {
          // clearHighlight();
          infoWindow.close();
        });
      }
      cont.style.width = "auto";
      cont.style.color = "black";
      cont.style.textAlign = "center";
      cont.innerHTML = infoContent;
    }
    if (color === "#8DDFB3" || color === "blue") {
      const cont = document.createElement("div");
      cont.classList.add("infocontent2");
      // google.maps.event.addListener(square, "click", function () {
      //   highlight(this, location, cont);
      // });
      createClickablePoly(square, map);
    }
    if (data !== undefined) {
      google.maps.event.addListener(map, "idle", idelHandler);
    }
  };

  const updateCoordinate = async () => {
    let updateGeoLocationData = { ...shapeCoordinate };
    updateGeoLocationData["token_id"] = tokenId;

    const updateGeoLocationRes = await updateGeoLocation(updateGeoLocationData);
    if (updateGeoLocationRes.status) {
      setSelectedNft(0);
      getMapDetail();
      showAlert("SUCCESS", "Success", updateGeoLocationRes.message);
    } else {
      showAlert("ERROR", "Error", updateGeoLocationRes.message);
    }
  }

  const getTotalNftCount = async (coordinate) => {
    let getTotalNftData = { ...coordinate };
    getTotalNftData["token_id"] = tokenId;

    const getTotalNftRes = await getTotalNft(getTotalNftData);
    if (getTotalNftRes.status) {
      setSelectedNft(getTotalNftRes.selected_nfts)
      // showAlert("SUCCESS", "Success", getTotalNftRes.message);
    } else {
      showAlert("ERROR", "Error", getTotalNftRes.message);
    }
  }

  const highlight = (obj, location, cont) => {
    const num = parseFloat(location.id, 10);

    if (nft.includes(num)) {
      const data = nft.filter((prev) => prev !== num);

      setNft(data);
      dispatch(setNftId(data));
      showAlert("SUCCESS", "Success", `NFT removed successfully with ID ${num}`);
    } else {
      setNft([...nft, num]);
      dispatch(setNftId([...nft, num]));
      showAlert("SUCCESS", "Success", `NFT add successfully with ID ${num}`);
    }
  };

  const createClickablePoly = (poly, map) => {
    // var contentString = html;
    google.maps.event.addListener(poly, "click", function (event) {
      /*
       * infoWindow.setContent(contentString);
       *  infoWindow.setPosition(event.latLng);
       *  infoWindow.open(map);
       */
    });
  };

  const idelHandler = () => {
    window.clearTimeout(timeoutHandler);
    timeoutHandler = window.setTimeout(function () {
      apiCall();
    }, 500);
  };

  const apiCall = async () => {
    const ne = await map.getBounds().getNorthEast();
    const sw = await map.getBounds().getSouthWest();
    const user_id = parseFloat(localStorage.getItem("user_id"), 10);
    const access_token = localStorage.getItem("access_token");

    // alert(ne + "--" + sw);
    const val = {
      token_id: tokenId,
      latitude2: ne.lat(),
      longitude2: ne.lng(),
      longitude1: sw.lng(),
      latitude1: sw.lat(),
      user_id,
      access_token,
    };

    const res = await getCoordinates(val);
    if (res.success) {
      const aval = res.geofencing.available;
      const rese = res.geofencing.reserve;
      const sol = res.geofencing.sold;
      const user = res.geofencing.user_nft;
      const result = sol.filter((o1) => !user.some((o2) => o1.id === o2.id));

      setUserNft(user);

      if (aval && aval.length) {
        for (let index = 0; index < aval.length; index++) {
          coordinates.push(calculateCoordinates(aval[index], index, "#8DDFB3"));
        }
      }
      if (rese && rese.length) {
        for (let index = 0; index < rese.length; index++) {
          coordinates.push(calculateCoordinates(rese[index], index, "#FFF282"));
        }
      }
      if (result && result.length) {
        for (let index = 0; index < result.length; index++) {
          coordinates.push(calculateCoordinates(result[index], index, "#DEB69C"));
        }
      }
      if (user && user.length) {
        for (let index = 0; index < user.length; index++) {
          coordinates.push(calculateCoordinates(user[index], index, "blue"));
        }
      }
    }
  };

  const handleMintToken = async () => {
    if (!isMetamaskInstalled) {
      showAlert("ERROR", "Error", "Please Install Metamask to use this service");
      return;
    }
    const result = await metamaskService.mintToken(1);
  };

  const removeIframe = () => {
    var chkiframe = setInterval(function () {
      if (document.getElementsByTagName("iframe").length > 0) {
        clearInterval(chkiframe);

        var iframe = document.getElementsByTagName("iframe")[0];
        iframe.parentNode.removeChild(iframe);
      }
    }, 2000);
  };

  const hiddenFileInput = React.useRef(null);

  const handleImageUpload = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    formik.setFieldValue("image", fileUploaded);
  };

  const getTokenList = async () => {
    const data = {
      page_no: 1,
      crypto_token_id: selectedCrypto,
      request_type: "lock"
    };
    return await getTokenLockList(data);
  }

  const popUpModal = (obj, location, cont, event) => {
    infoWindow.setContent(cont);
    infoWindow.setPosition(event.latLng);
    infoWindow.open(map);
    obj.setOptions({
      // strokeColor: "#FF0000",
      // fillColor: "white",
      // fillOpacity: 0.35,
      infoWindow: infoWindow,
    });

    highlitBB = obj;
  };

  const initialValues = {
    text: "",
    image: "",
  };

  const formSchema = Yup.object().shape({
    text: Yup.string()
      .trim()
      .required("Description cannot not be empty"),
    image: Yup.mixed()
      .required("Please select image")
      .test("fileType", "For file upload only support .jpg, .jpeg, and .png file", value => SUPPORTED_FORMATS.includes(value.type))
  })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      let data = { ...values };
      const geofencing_ids = [];

      if (userNft?.length) {
        userNft.forEach((ele) => {
          geofencing_ids.push(ele.id);
        })
      }

      const tokenLockList = await getTokenList();
      if (tokenLockList?.token_map_list?.length) {
        data["token_map_id"] = tokenLockList?.token_map_list[0]?.id;
      }

      data["geofencing_ids"] = geofencing_ids;
      data["isFormData"] = true;

      const res = await uploadImage(data);
      if (res?.status) {
        showAlert("SUCCESS", "Success", res.message)
      } else {
        showAlert("ERROR", "ERROR", res.message)
      }

      setIsLoading(false);
    },
  });

  return (
    <>
      <div className={showform ? "show-form d-flex justify-content-center" : "hide-form"}>
        <div className="modal-box">
          <div className="col p-4">
            <div className="row">
              <div className="col font-20 font-w-600 my-4 text-dark">Upload Image</div>
            </div>
            <div className="row">
              <div className="col text-dark">Description</div>
            </div>
            <div className="row my-3">
              <div className="col modal-map">
                <textarea
                  type="text"
                  className="form-control bg-gr"
                  id="inputEmail4"
                  placeholder="Description"
                  name="text"
                  {...formik.getFieldProps("text")}
                />
              </div>
              {(formik.touched.text || formik.errors.text) && (
                <p className="text-danger">
                  <small>{formik.errors.text}</small>
                </p>
              )}
            </div>
            <div className="row">

              {
                formik?.values?.image?.name ?
                  <div className="input-group field-style" >
                    <span className="input-group-text" style={{ width: "50px", cursor: "pointer" }} id="basic-addon1" onClick={() => handleImageUpload()}>
                      <img src={uploadIcon} className="img-fluid" alt="" />
                    </span>
                    <span className="form-control font-14" style={{ cursor: "default", borderRadius: "0px 5px 5px 0px" }} >
                      {formik?.values?.image?.name}
                    </span>
                    <input type="file" id="file" accept=".jpg, .jpeg, .png" ref={hiddenFileInput} onChange={handleChange} style={{ display: "none" }} />
                  </div>
                  :
                  <>
                    <button className="btn btn-primary mt-4 f-bold ad-sc no-bdr" onClick={() => handleImageUpload()}>
                      Choose Image
                    </button>
                    <input type="file" id="file" ref={hiddenFileInput} onChange={handleChange} style={{ display: "none" }} />
                  </>
              }
              {(formik.touched.image || formik.errors.image) && (
                <p className="text-danger">
                  <small>{formik.errors.image}</small>
                </p>
              )}
              {/* <p className="text-dark">{formik?.values?.image?.name}</p> */}
            </div>
            <div className="row">
              {
                isLoading ?
                  <button type="submit" className="btn mt-4 f-bold kyc-sb no-bdr" disabled>
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </button>
                  :
                  <button type="submit" className="btn btn-primary mt-4 f-bold kyc-sb no-bdr" onClick={formik.handleSubmit}>
                    Upload Image
                  </button>
              }
            </div>
          </div>
        </div>
      </div>

      <div id="map" className="mfp-hide white-popup-block p-4 position-relative mt-5">
      </div>
      <div className="main-description">
        <div className="map-description">
          <div className="map-description-box">
            <div className="box active-nft" />
            <p>Active NFT</p>
          </div>
          <div className="map-description-box">
            <div className="box reserve-nft" />
            <p>Reserve NFT</p>
          </div>
          <div className="map-description-box">
            <div className="box sold-nft" />
            <p>Sold NFT</p>
          </div>
          <div className="map-description-box">
            <div className="box user-nft" />
            <p>User NFT</p>
          </div>
        </div>
      </div>
      {
        JSON.parse(isAdmin) && <p className="text-dark">You have {selectedNft} NFT select</p>
      }

      {
        isShowUpdateBtn &&
        <button type="submit" id="updateShapeCoordinate" className="btn btn-primary f-bold kyc-sb no-bdr" onClick={() => updateCoordinate()}>
          Update
        </button>
      }
    </>
  );
};

// eslint-disable-next-line new-cap
export default React.memo(GoogleApiWrapper({ apiKey: "AIzaSyBbMCNnteP_t-kr5fuyQxHaY_vwEgdcF_E", libraries: ["drawing", 'places', 'visualization'] })(UploadImageMapContainer));
