import React from "react";
import { useNavigate } from "react-router";
import { TAB } from "../../app/constants/constants";

const NatureTabs = ({ setPage }) => {
  const navigate = useNavigate();

  let isAdmin = localStorage.getItem("isAdmin");
  isAdmin = JSON.parse(isAdmin);

  const handleChange = (page) => {
    setPage(page);
    if (page === TAB.ABOUT) {
      navigate('/about')
    }
  };

  return (
    <ul className="nav nav-tabs mb-3 gap-2 gap-lg-3 border-bottom-0" id="myTab" role="tablist">
      <li className="nav-item" role="presentation">
        <button
          className="nav-link active btn btn-primary"
          id="naturenft-tab"
          onClick={() => handleChange(TAB.NATURE)}
          data-bs-toggle="tab"
          data-bs-target="#naturenft"
          type="button"
          role="tab"
          aria-controls="naturenft"
          aria-selected="true"
        >
          Nature NFT
        </button>
      </li>

      {
        !isAdmin &&
        <li className="nav-item" role="presentation">
          <button
            onClick={() => handleChange(TAB.ABOUT)}
            className="nav-link btn btn-primary"
            id="energynft-tab"
            data-bs-toggle="tab"
            data-bs-target="#energynft"
            type="button"
            role="tab"
            aria-controls="energynft"
            aria-selected="false"
          >
            About
          </button>
        </li>
      }
    </ul>
  );
};

export default NatureTabs;
