import { getLoginUserDetail } from "../pages/home/services/home";
// import { getLastTSPDetail } from "../utils/token";

const checkLogin = async (data) => {
  const res = await getLoginUserDetail(data);
  if (res?.status) {
    // const multiTSP = getLastTSPDetail(res);
    localStorage.setItem("cash_decimal_limit", res.cash_decimal_limit);
    localStorage.setItem("crypto_decimal_limit", res.crypto_decimal_limit);
    localStorage.setItem("sumsub_id", res.sumsub_user_applicant_id);
    localStorage.setItem("kyc_done", res.kyc_done);
    localStorage.setItem("user_email", res.user_email);
    localStorage.setItem("user_name", res.user_name);
    localStorage.setItem("wallet_address", res.tsp.wallet_address);
    localStorage.setItem("status", res.status);
    localStorage.setItem("otp_check", 'false');
    localStorage.setItem("aws_access_token", res.tsp?.aws_access_token);
    localStorage.setItem("aws_session", res.tsp?.aws_session);
    localStorage.setItem("multi_tsp", JSON.stringify(res.multi_tsp));
    localStorage.setItem("access_token", res.tsp.access_token);
    localStorage.setItem("user_id", res.tsp.user_id);
    localStorage.setItem("isAdmin", res?.tsp?.is_admin ? res.tsp.is_admin : false);
    localStorage.setItem("user_bank_details", JSON.stringify({ ...res?.multi_tsp?.wallet?.bank_detail, account_holder_name: res.user_name }));
    return true;
  }
};

export {
  checkLogin
};
