/* eslint-disable jsx-a11y/anchor-is-valid */
import "../../../assets/css/sidebar.css";
import React from "react";
import userProfile from "../../../assets/images/user-profile.png";
import { getUserName, handleLogout } from "../../../app/utils/token";

const SideBar = () => {
  const name = getUserName();
  let shortName;
  if (name) {
    shortName = name.substring(0, 12) + "...";
  }

  let isAdmin = localStorage.getItem("isAdmin");
  isAdmin = JSON.parse(isAdmin);

  const handleRedirect = () => {
    window.location.href = `${process.env.REACT_APP_WALLET_URL}`;
  };

  return (
    <>
      <a className="logged-user btn-warning" href="#" title={name}>
        <img src={userProfile} alt="" />{" "}
        <small className="font-16">{shortName}</small>
      </a>
      {
        !isAdmin &&
        <div className="nav-link font-5F738C" activeclassname="active" onClick={handleRedirect}>
          <i className="fal fa-signal-alt-2 font-30"></i>
          <br />
          <small className="font-12">Dashboard</small>
        </div>
      }

      <div className="nav-link font-5F738C" activeclassname="active" onClick={handleLogout}>
        <i className="far fa-sign-out"></i>
        <br />
        <small className="font-12">Log Out</small>
      </div>
    </>
  );
};

export default SideBar;
