import { logout } from "../pages/home/services/home";

const getUserName = () => {
  const userName = localStorage.getItem("user_name");
  return userName;
};

const handleLogout = async () => {
  const res = await logout();
  if (res?.success) {
    clearLocalStorage();
    window.location.href = `${process.env.REACT_APP_LOGIN_URL}`;
  }
}

const clearLocalStorage = () => {
  localStorage.clear();
};

const getCashLimit = () => {
  const getCash = localStorage.getItem("cash_decimal_limit");
  return getCash;
};

const getCryptoLimit = () => {
  const getCrypto = localStorage.getItem("crypto_decimal_limit");
  return getCrypto;
};

const getAccessToken = () => {
  const accessToken = localStorage.getItem("access_token");
  return accessToken;
};

const getUserId = () => {
  const userId = localStorage.getItem("user_id");
  return userId;
};

const getWalletAddress = () => {
  const walletAddress = localStorage.getItem("wallet_address");
  return walletAddress;
};

const setWalletAddress = (data) => {
  localStorage.setItem("wallet_address", data);
};

const getQueryParams = () => {
  const location = window.location.search;
  const params = new URLSearchParams(location);

  const userId = parseInt(params.get('user_id'));
  const accessToken = params.get('access_token');

  return {
    user_id: userId,
    access_token: accessToken
  };
};

const getLastTSPDetail = (data) => {
  const multiTSP = data?.multi_tsp;
  if (multiTSP) {
    const person = [];
    Object.keys(multiTSP).forEach((index) => {
      person.push(multiTSP[index]);
    });
    const totalTSP = person.length - 1;
    return person[totalTSP];
  }
};

const loginRedirection = () => {
  clearLocalStorage();
  if (window.location.pathname === "/") {
    window.location.href = `${process.env.REACT_APP_LOGIN_URL}?redirect_url=${window.location.href}dashboard`;
  } else {
    window.location.href = `${process.env.REACT_APP_LOGIN_URL}?redirect_url=${window.location.href}`;
  }
}

const historyResponseSet = (data) => {
  const responseArr = [];
  Object.keys(data).forEach(index => {
    const response = {
      project: index,
      ...data[index]
    }
    if (response?.project && response?.purchased_token && response?.invested_amount) {
      responseArr.push(response);
    }
  });
  return responseArr;
}

export {
  getUserName,
  clearLocalStorage,
  handleLogout,
  getCashLimit,
  getCryptoLimit,
  getAccessToken,
  getUserId,
  getWalletAddress,
  setWalletAddress,
  getQueryParams,
  getLastTSPDetail,
  loginRedirection,
  historyResponseSet,
}