import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import DashboardBanner from "../../dashboard/components/dashboardBanner";
import DashboardHeading from "../../dashboard/components/dashboardHeading";
import DashboardSummary from "../../dashboard/components/dashboardSummery";
import SmartContractModal from "../../../../components/modal/smartContractModal";
import NFTFormCrypto from "./nftFormCrypto";
import { getWalletAddress } from "../../../utils/token";
import { NATURE_NFT_TAB } from "../../../constants/constants";
import NatureNftTab from "./natureNftTab";
import NftFormWithPredefineArea from "./nftFormWithPredefineArea";

const NatureNFTTabDashboard = ({ enegrynft = "yes" }) => {
  const { selectedCrypto, masterData } = useSelector((state) => state.nft);
  const [page, setPage] = useState(NATURE_NFT_TAB.BUY_TOKEN)

  const exchangeData = masterData?.exchange_data?.filter(ele => ele?.crypto_token_id === selectedCrypto)[0];
  let message = {
    cryptoPercent: exchangeData?.tsp_discount_percentage,
    cryptoMessage: exchangeData?.tsp_discount_message,
  };

  const value = useRef(null);
  value.current = {
    walletAddress: getWalletAddress(),
    exchangeData: masterData?.exchange_data?.filter(ele => ele?.crypto_token_id === selectedCrypto)[0],
    token: masterData?.crypto_tokens?.filter(ele => ele?.id === selectedCrypto)[0],
    currency: masterData?.exchange_data[0].supported_fiat_currencies,
    currencyToken: masterData?.crypto_types,
  };

  return (
    <div className="card border-0">
      <div className="tab-content" id="myTabContent">
        <div className="tab-pane fade show active" id="energy-nft-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
          <DashboardBanner />
          <div className="service-content">
            <DashboardHeading tabSwitcher="no" />
            <DashboardSummary />
            <SmartContractModal />

            <NatureNftTab setPage={setPage} />

            {
              page === NATURE_NFT_TAB.BUY_TOKEN ?

                <div className="row">
                  {value.current.token !== undefined && (
                    <NFTFormCrypto data={value.current} ddshow={true} />
                  )}
                  <div className="col-12 col-lg-6">
                    <div className="result-box text-center">
                      <h2 className="text-white">
                        {message.cryptoPercent}% Discount for a limited period.
                      </h2>
                      <div className="form-discount" dangerouslySetInnerHTML={{ __html: message.cryptoMessage }} />
                    </div>
                  </div>
                </div>
                :
                <div className="row">
                  {value.current.token !== undefined && (
                    <NftFormWithPredefineArea data={value.current} ddshow={true} />
                  )}
                </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default NatureNFTTabDashboard;
