import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import PrivateRoute from "../hooks/privateRoute";
import PublicRoute from '../hooks/public-route';
// import Home from '../pages/home';
import Dashboard from '../pages/dashboard';
import WalletAddress from '../pages/walletAddress';
import NatureNft from '../pages/naturenft';
import UploadImage from '../pages/uploadImage';
// import LockImage from '../pages/lockImage';
import About from "../pages/about";

const RoutesPage = () => {
  let isAdmin = localStorage.getItem("isAdmin");
  isAdmin = JSON.parse(isAdmin);

  return (
    <Routes>
      {
        isAdmin ?
          <Route element={<PublicRoute />}>
            <Route path="/" element={<Navigate to="/upload-image" />} />
            <Route path="/:pageType" element={<Navigate to="/upload-image" />} />
          </Route>
          :
          <Route element={<PublicRoute />}>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/:pageType" element={<Navigate to="/dashboard" />} />
          </Route>
      }
      {
        isAdmin ?
          <Route element={<PrivateRoute />}>
            <Route path="/upload-image" element={<UploadImage />} />
          </Route>
          :
          <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/wallet-address" element={<WalletAddress />} />
            <Route path="/naturenft" element={<NatureNft />} />
            <Route path="/upload-image" element={<UploadImage />} />
            {/* <Route path="/lock-map" element={<LockImage />} /> */}
            <Route path="/about" element={<About />} />
          </Route>
      }
    </Routes>
  )
}

export default RoutesPage;