const MESSAGE_VARIANT = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  WARNING: "WARNING"
};

const TAB = {
  NATURE: "Nature",
  ABOUT: "About"
}

const NATURE_NFT_TAB = {
  BUY_TOKEN: "Buy-Token",
  BUY_TOKEN_WITH_PREDEFINE_AREA: "Buy-Token-With-Predefine-Area"
}

export {
  MESSAGE_VARIANT,
  TAB,
  NATURE_NFT_TAB,
}