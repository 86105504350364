import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { homeConstants } from "./homeAction";

const initialState = {
  isLoggedIn: false,
  user: null,
  authToken: null,
  headerList: [],
  smartContract: null,
  isMetamaskInstalled: false,
  isWalletConnected: false,
  walletAddress: null,
  wallet: null,
  tokens: 0,
};

const persistConfig = {
  storage,
  key: "v706-demo1-auth",
  whitelist: ["user", "authToken"],
};

const homeReducer = persistReducer(persistConfig, (state = initialState, action) => {
  switch (action.type) {
    case homeConstants.SET_HEADER_LIST:
      return {
        ...state,
        headerList: action.payload.headerList,
      };
    case homeConstants.SET_SMART_CONTRACT:
      return {
        ...state,
        smartContract: action.payload.smartContract,
      };
    case homeConstants.SET_METAMASK_INSTALLED_STATUS:
      return {
        ...state,
        isMetamaskInstalled: action.payload.status,
      };
    case homeConstants.SET_IS_WALLET_CONNECTED:
      return {
        ...state,
        isWalletConnected: action.payload.status,
      };
    case homeConstants.SET_IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.payload.status,
      };
    case homeConstants.SET_WALLET_ADDRESS:
      return {
        ...state,
        walletAddress: action.payload.walletAddress,
      };
    case homeConstants.SET_SELECT_WALLET:
      return {
        ...state,
        wallet: action.payload.wallet,
      };
    case homeConstants.SET_MAP_TOKEN:
      return {
        ...state,
        tokens: action.payload.tokens,
      };
    default:
      return state;
  }
});

export default homeReducer