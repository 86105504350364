/* eslint-disable no-negated-condition */
import axios from "axios";
import api_end_point from "../../../constants/api_end_point";

const dashboardAPI = async (val) => {
  const response = await axios({
    method: "Post",
    url: `${api_end_point.BASE_URL}${api_end_point.DASHBOARD_URL}`,
    data: val
  });
  return response?.data;
};

const userDashboardAPI = async (val) => {
  const response = await axios({
    method: "Post",
    url: `${api_end_point.BASE_URL}${api_end_point.USER_DASHBOARD}`,
    data: val
  });
  return response?.data;
};

const getSmartContract = async (values) => {
  const response = await axios({
    method: "Post",
    url: `${api_end_point.BASE_URL}${api_end_point.SMART_CONTRACT}${values}`,
  });
  return response?.data;
};

const getMasterData = async () => {
  const response = await axios({
    method: "Post",
    url: `${api_end_point.BASE_URL}${api_end_point.ASSET_MASTER_DATA}`,
  });
  return response?.data;
};

const updateWalletAddress = async (values) => {
  const data = {
    wallet_address: values.walletAddress,
  };
  const response = await axios({
    method: "Post",
    url: `${api_end_point.BASE_URL}${api_end_point.UPDATE_WALLET_ADDRESS}`,
    data: data
  });
  return response?.data;
};

const createAssetRequest = async (values) => {
  const response = await axios({
    method: "Post",
    url: `${api_end_point.BASE_URL}${api_end_point.CREATE_ASSET_REQUEST}`,
    data: values
  });
  return response?.data;
};

const getPriceToken = async (values) => {
  const response = await axios({
    method: "Post",
    url: `${api_end_point.BASE_URL}${api_end_point.GET_TOKEN_PRICE}`,
    data: values
  });
  return response?.data;
};

const cryptoConfirmPayment = async (values) => {
  const response = await axios({
    method: "Post",
    url: `${api_end_point.BASE_URL}${api_end_point.CRYPTO_CONFIRM_PAYMENT}`,
    data: values
  });
  return response?.data;
};

const rejectAssetRequest = async (values) => {
  const response = await axios({
    method: "Post",
    url: `${api_end_point.BASE_URL}${api_end_point.REJECT_ASSET_REQUEST}`,
    data: values
  });
  return response?.data;
};

const postTokenLock = async (values) => {
  const response = await axios({
    method: "Post",
    url: `${api_end_point.BASE_URL}${api_end_point.TOKEN_LOCK}`,
    data: values
  });
  return response?.data;
};

const getSignature = async (values) => {
  const response = await axios({
    method: "Post",
    url: `${api_end_point.BASE_URL}${api_end_point.SIGNATURE}`,
    data: values
  });
  return response?.data;
};

const getTokenLockList = async (values) => {
  const response = await axios({
    method: "Post",
    url: `${api_end_point.BASE_URL}${api_end_point.TOKEN_MAP_LOCK_LIST}`,
    data: values
  });
  return response?.data;
};

const searchAssetRequest = async (values) => {
  const response = await axios({
    method: "Post",
    url: `${api_end_point.BASE_URL}${api_end_point.SEARCH_ASSET_REQUEST}`,
    data: values
  });
  return response?.data;
};

const getCoordinates = async (values) => {
  const response = await axios({
    method: "Post",
    url: `${api_end_point.BASE_URL}${api_end_point.MAP_BOUND}`,
    data: values
  });
  return response?.data;
};

const unlockNFT = async (values) => {
  const response = await axios({
    method: "Post",
    url: `${api_end_point.BASE_URL}${api_end_point.UNLOCK_NFT}`,
    data: values
  });
  return response?.data;
};

const unlockTokens = async (values) => {
  const response = await axios({
    method: "Post",
    url: `${api_end_point.BASE_URL}${api_end_point.UNLOCK_TOKEN}`,
    data: values
  });
  return response?.data;
};

const getInvestmentDetail = async () => {
  const response = await axios({
    method: "Post",
    url: `${api_end_point.BASE_URL}${api_end_point.INVESTMENT_DETAIL}`,
  });
  return response?.data;
};

export {
  dashboardAPI,
  userDashboardAPI,
  getSmartContract,
  getMasterData,
  updateWalletAddress,
  createAssetRequest,
  getPriceToken,
  cryptoConfirmPayment,
  rejectAssetRequest,
  postTokenLock,
  getSignature,
  getTokenLockList,
  searchAssetRequest,
  getCoordinates,
  unlockNFT,
  unlockTokens,
  getInvestmentDetail,
}